import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useStaticQuery, graphql } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import ScalableImage from 'components/UI/ScalableImage';

import cmnStyles from 'styles/pages.module.scss';
import SearchText from '../Search';
import styles from './Intro.module.scss';

interface FAQIntroProps {
  onSearchChange: (searchText: string) => void;
}

const FAQIntro: React.FC<FAQIntroProps> = ({
  onSearchChange,
}) => {
  const onSearchTextChange = (searchTerm: string): void => {
    onSearchChange(searchTerm);
  };
  const {
    imageIntro,
    imageIntroMobile,
  } = useStaticQuery(
    graphql`
      query {
          imageIntro: file(relativePath: { eq: "faq/intro.png" }) {
              childImageSharp {
                  fluid(maxWidth: 900) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64,
                  }
              }
          }
          imageIntroMobile: file(relativePath: { eq: "faq/intro-mobile.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64,
                }
            }
          }
        }
      `,
  );

  return (
    <Container className={styles.container}>
      <Row>
        <Col
          xs={{ span: 12, order: 2 }}
          sm={{ span: 12, order: 2 }}
          md={{ span: 12, order: 2 }}
          lg={{ span: 5, order: 1 }}
          xl={{ span: 5, order: 1 }}
          className={cmnStyles.alignSectionVerticallyCenter}
        >
          <h1 className={cmnStyles.articleTitle}>
            <FormattedMessage id="components.faq.title" />
          </h1>
          <div className={cmnStyles.article}>
            <SearchText onChange={onSearchTextChange} />
          </div>
        </Col>
        <Col
          xs={{ span: 12, order: 1 }}
          sm={{ span: 12, order: 1 }}
          md={{ span: 12, order: 1 }}
          lg={{ span: 6, order: 2 }}
          xl={{ span: 6, order: 2 }}
        >
          <ScalableImage
            imageDesktop={imageIntro}
            imageMobile={imageIntroMobile}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default FAQIntro;
