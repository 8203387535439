import React from 'react';
import GatsbyImage from 'gatsby-image';
import { GatsbyFluidImage } from 'types/gatsby/image';
import styles from './ScalableImage.module.scss';

type ScalableImageProps = {
  imageDesktop: GatsbyFluidImage;
  imageMobile: GatsbyFluidImage;
}
const ScalableImage: React.FC<ScalableImageProps> = ({
  imageDesktop,
  imageMobile,
}) => (
  <>
    <div className={styles.imageDesktop}>
      <GatsbyImage fluid={imageDesktop.childImageSharp.fluid} />
    </div>
    <div className={styles.imageMobile}>
      <GatsbyImage fluid={imageMobile.childImageSharp.fluid} />
    </div>
  </>
);

export default ScalableImage;
