/* eslint-disable import/prefer-default-export */
// HTML manipulation helper

export function isDivTop(el: HTMLElement | null): boolean {
  let inRect = false;
  if (el !== null) {
    const { top } = el.getBoundingClientRect();
    inRect = top > -20 && top <= window.innerHeight;
  }
  return inRect;
}
