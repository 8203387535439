import React from 'react';
import styles from './Indicator.module.scss';

interface AnchorMenuIndicatorProps {
  posIndex: number;
  offsetTop?: number;
  markerContainerStyle?: React.CSSProperties;
}

const AnchorMenuIndicator: React.FC<AnchorMenuIndicatorProps> = ({
  posIndex = 0,
  offsetTop = 0,
  markerContainerStyle = {},
}) => {
  const baseHeight = offsetTop > 0 ? offsetTop : 35; // px
  const newPos = posIndex * baseHeight;
  return (
    <div className={styles.container} style={markerContainerStyle}>
      <div
        className={styles.marker}
        style={{ top: `${newPos}px` }}
      />
    </div>
  );
};

export default AnchorMenuIndicator;
