/* eslint-disable class-methods-use-this */ // needed
// Service that handles grab of FAQ content from ZendDesk
import { api } from 'helpers/server/faqApi';
import { Languages, getFAQLocaleFromLanguage } from 'helpers/utils/locale';
import { AnyType } from 'types/anytype';

import {
  SectonsSuccessType,
  SectionsType,
  SectionRaw,
  Section,
  ArticlesType,
  Article,
  SectionContent,
} from 'types/faq/service';

export default class FAQService {
  mainPath: string;

  categoryId: number;

  constructor() {
    this.mainPath = 'https://onoff.zendesk.com/api/v2/help_center/';
    this.categoryId = 200435251;
  }

  async getSections(lang = Languages.En.toString(), sort = false): Promise<SectionsType> {
    const path = `${this.mainPath}${getFAQLocaleFromLanguage(lang)}/categories/${this.categoryId}/sections.json`;

    const response = await api.get<SectonsSuccessType>(path, { });
    if (response && response.sections) {
      const sectionList = response.sections;
      let list: Section[] = sectionList.map((item: SectionRaw) => ({
        id: item.id, sectionTitle: `${item.name}`, hashTag: `#${item.name.replace(/\s/g, '')}`,
      }));
      list = sort ? this.sortBySectionTitle(list) : list;
      return { data: list };
    }
    return { data: [] };
  }

  async getSectionsAndTheirArticles(
    sections: Array<Section>,
    lang = Languages.En.toString(),
    sort = true,
  ): Promise<Array<SectionContent>> {
    if (sections && sections.length < 1) { return []; }
    let content: Array<SectionContent> = [];

    await Promise.all(sections.map(async (section) => {
      const articles = await this.getArticlesPerSection(section.id, getFAQLocaleFromLanguage(lang));
      content.push({
        sectionTitle: section.sectionTitle,
        hashTag: section.hashTag,
        articles: articles.data,
      });
    }));
    content = sort ? this.sortBySectionTitle(content) : content;
    return content;
  }

  async getArticlesPerSection(sectionId = -1, locale = 'en-us'):
  Promise<ArticlesType> {
    const path = `${this.mainPath}${locale}/sections/${sectionId}/articles.json`;
    const response = await api.get(path, { });
    if (response) {
      const articleList = response.articles;
      const list: Article[] = articleList.map((item: Article) => ({
        id: item.id, title: item.title, body: item.body,
      }));
      return { data: list };
    }
    return { data: [] };
  }

  async searchArticles(query = '', lang = Languages.En.toString()):
  Promise<ArticlesType> {
    if (query && query.length < 3) { return { data: [] }; }
    const path = `${this.mainPath}articles/search.json`;
    const response = await api.get(path, {
      locale: getFAQLocaleFromLanguage(lang),
      query,
      category: this.categoryId,
    });

    if (response) {
      const foundList = response.results;
      const list: Article[] = foundList.map((item: Article) => ({
        id: item.id, title: item.title, body: item.body,
      }));
      return { data: list };
    }
    return { data: [] };
  }

  sortBySectionTitle(arrayObj: Array<Section | SectionContent | AnyType>):
    Array<Section | SectionContent | AnyType> {
    const sorted = arrayObj.sort((t1, t2) => {
      const title1 = t1.sectionTitle.toLowerCase();
      const title2 = t2.sectionTitle.toLowerCase();
      if (title1 > title2) { return 1; }
      if (title1 < title2) { return -1; }
      return 0;
    });
    return sorted;
  }
}
