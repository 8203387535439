/* eslint-disable class-methods-use-this */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { MiniSignalBinding } from 'mini-signals';
import mSignal, { SIGNALS } from 'helpers/constants/signals';

import AnchorMenuIndicator from './Indicator';
import styles from './AnchorMenu.module.scss';

type AnchorMenuProps = {
  links: Array<AnchorMenuLink>;
  anchorLinkOffsetTop: number;
  headerPos?: PostionType;
  isLabelTranslationRequired?: boolean;
  headerItemCustomStyle?: React.CSSProperties;
  markerContainerStyle?: React.CSSProperties;
  headerOffsetTop?: number;
}

type AnchorMenuState = {
  indexLinks: linkIndexTable[];
  isScrollLock: boolean;
  activeLink: string;
  activePosItemIndex: number;
}

export type AnchorMenuLink = {
  header: linkItem;
  subMenu: Array<linkItem>;
}

type PostionType = {
  top: string;
  left: string;
}

type linkItem = {
  label: string;
  hashTag: string;
}

type linkIndexTable = {
  label: string;
  hashTag: string;
  index: number;
  isHeader: boolean;
}

const TOP_OFFSET = 100; // top item maximum height

export default class AnchorMenu extends React.Component<AnchorMenuProps, AnchorMenuState> {
  signalBind: MiniSignalBinding | undefined;

  constructor(props: AnchorMenuProps) {
    super(props);
    this.state = {
      indexLinks: [],
      activeLink: '',
      isScrollLock: false,
      activePosItemIndex: 0,
    };
    this.onActiveHashTag = this.onActiveHashTag.bind(this);
    this.onListItemClick = this.onListItemClick.bind(this);
    this.trackScrolling = this.trackScrolling.bind(this);
  }

  componentDidMount(): void {
    this.setAnchorLinks();
    this.signalBind = mSignal.add(this.onActiveHashTag);
    document.addEventListener('scroll', this.trackScrolling);
  }

  componentDidUpdate(prevProps: AnchorMenuProps): void {
    const { links } = this.props;
    if (prevProps.links !== links) {
      this.setAnchorLinks();
    }
  }

  componentWillUnmount(): void {
    if (this.signalBind !== undefined) {
      this.signalBind.detach();
    }
    document.removeEventListener('scroll', this.trackScrolling);
  }

  onActiveHashTag(signal: string, activeScrollHashTag: string): void {
    const { isScrollLock, indexLinks } = this.state;
    if (!isScrollLock && signal === SIGNALS.ACTIVE_HUMAN_SCROLL) {
      const posItem = this.getIndexItemFromHashTag(indexLinks, activeScrollHashTag);
      const foundIndex = posItem && posItem.index ? posItem.index : -1;
      if (foundIndex > -1) {
        if (window.pageYOffset > TOP_OFFSET) {
          this.setState({ activeLink: posItem.label, activePosItemIndex: foundIndex });
        }
      }
    }
  }

  onListItemClick(e: React.MouseEvent | React.KeyboardEvent, title: string): void {
    e.stopPropagation();
    this.resetHumanScrollLock(2000);
    const { indexLinks } = this.state;
    const posItem = this.getIndexItem(indexLinks, title);
    this.setState({ activeLink: title, activePosItemIndex: posItem.index });
  }

  setAnchorLinks(): void {
    const { links } = this.props;
    this.setState({ indexLinks: this.indexAllList(links) });
  }

  getIndexItem(listLinks: Array<linkIndexTable>, activeTitle: string): linkIndexTable {
    return listLinks.filter((item) => item.label === activeTitle)[0];
  }

  getIndexItemFromHashTag(
    listLinks: Array<linkIndexTable>, activeHashTag: string,
  ): linkIndexTable {
    return listLinks.filter((item) => item.hashTag === activeHashTag)[0];
  }

  trackScrolling(): void {
    const { isScrollLock } = this.state;
    if (window.pageYOffset < TOP_OFFSET && !isScrollLock) {
      this.setState({ activePosItemIndex: 0 });
    }
  }

  resetHumanScrollLock(timeoutUnlock = 1000): void {
    const { isScrollLock } = this.state;
    if (!isScrollLock) {
      this.setState({ isScrollLock: true });
      setTimeout(() => { this.setState({ isScrollLock: false }); }, timeoutUnlock);
    }
  }

  indexAllList(listLinks: Array<AnchorMenuLink>): Array<linkIndexTable> {
    const indexItems: Array<linkIndexTable> = [];
    let counter = 0;
    listLinks.forEach((item) => {
      indexItems.push({
        label: item.header.label,
        hashTag: item.header.hashTag,
        index: counter,
        isHeader: true,
      });
      counter += 1;
      item.subMenu.forEach((nestedItem) => {
        indexItems.push({
          label: nestedItem.label,
          hashTag: nestedItem.hashTag,
          index: counter,
          isHeader: false,
        });
        counter += 1;
      });
    });

    return indexItems;
  }

  render(): React.ReactNode {
    const {
      headerPos,
      links,
      anchorLinkOffsetTop,
      isLabelTranslationRequired = true,
      headerOffsetTop = 0,
      headerItemCustomStyle = {},
      markerContainerStyle = {},
    } = this.props;
    const { activeLink, activePosItemIndex } = this.state;
    const headerPosOverride = headerPos !== null && headerPos !== undefined
      ? { top: headerPos.top, left: headerPos.left } : {};

    const getSubMenu = (subItem: Array<linkItem>): React.ReactElement => {
      if (subItem && subItem.length <= 0) { return (<span />); }
      const SubMenus = subItem.map((item: linkItem) => {
        const itemClass = activeLink === item.label ? styles.activeListItem : '';
        return (
          <li
            key={item.label}
            className={itemClass}
          >
            <AnchorLink
              href={item.hashTag}
              onClick={(e: React.MouseEvent): void => {
                this.onListItemClick(e, item.label);
              }}
            >
              <FormattedMessage id={item.label} />
            </AnchorLink>
          </li>
        );
      });
      return (
        <ul className={styles.subMenuList}>
          {SubMenus}
        </ul>
      );
    };

    const Menus = links.map((item) => {
      const itemClass = activeLink === item.header.label ? styles.activeListItem : '';
      const Label = isLabelTranslationRequired
        ? (<FormattedMessage id={item.header.label} />) : (<>{item.header.label}</>);
      return (
        <li
          key={item.header.label}
          className={itemClass}
          style={headerItemCustomStyle}
        >
          <AnchorLink
            offset={anchorLinkOffsetTop}
            href={item.header.hashTag}
            onClick={(e: React.MouseEvent): void => {
              this.onListItemClick(e, item.header.label);
            }}
          >
            {Label}
          </AnchorLink>
          {getSubMenu(item.subMenu)}
        </li>
      );
    });
    return (
      <Container className={styles.container} style={headerPosOverride}>
        <Row>
          <Col>
            <AnchorMenuIndicator
              posIndex={activePosItemIndex}
              offsetTop={headerOffsetTop}
              markerContainerStyle={markerContainerStyle}
            />
            <ul className={styles.headerList}>
              {Menus}
            </ul>
          </Col>
        </Row>
      </Container>
    );
  }
}
